import React from "react";
import styled from "styled-components";

import { Wordmark } from "./Logo";
import Resources from "./Resources";
import halborn from "../../assets/logos/halborn.svg";
import Community from "./Community";
import { ImgLink } from "../../shared/Links";

const Wrap = styled.footer`
  align-items: center;
  display: grid;

  grid-gap: .5em 0;
  grid-template-areas:
  'logo'
  'halborn'
  'links'
  'resources'
  'copyright';
  grid-template-columns: auto;
  height: fit-content;
  justify-content: center;
  margin: 0 auto;
  place-items: center;
  width: 100%;
  margin-top: 1em;
  @media (min-width: 768px) {
    width: 90%;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    grid-gap: 2em 0;
    grid-template-areas:
      "logo halborn"
      "resources links"
      "copyright copyright";
    justify-content: space-between;
    align-items: baseline;
    margin: 10vh auto 5vh;
  }
}
`;

const FooterLogo = styled(Wordmark)`
  grid-area: logo;
  width: 250px;
  > img {
    width: 90%;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    height: 45px;
  }
`;

const Copyright = styled.p`
  color: hsl(0, 0%, 65%);
  grid-area: copyright;
  font-family: "archiaregular";
  letter-spacing: 1px;
  text-align: center;
  grid-area: copyright;
`;

const Link = styled(ImgLink)`
  margin: 0 auto;
  place-self: center;
  height: fit-content;
  width: fit-content;
  grid-area: halborn;
  > img {
    height: 45px;
    width: 220px;
  }
`;

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Wrap>
      <FooterLogo />
      <Resources />
      <Link
        dimensions={{ height: "45px", width: "220px", margin: "0 auto" }}
        title="Audit of APY.Finance by Halborn"
        link="https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/APY_Finance_smartcontract_halborn_report_V1.pdf"
        img={halborn}
      />
      <Community />
      <Copyright>copyright &copy; {year} apy.finance</Copyright>
    </Wrap>
  );
};

export default Footer;
