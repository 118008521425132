import React from 'react'
import styled from 'styled-components'
import { LINKS } from './links'
import { LinkTypes as Link } from './link-images'

const Wrap = styled.div`
  width: fit-content;
  grid-area: resources;
`

const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > a {
    margin: 0 10px;
  }
`

const LinkText = styled.p`
  @media (min-width: 768px) {
    font-size: 1.25em;
  }
`

const Resources = () => {
  return (
    <Wrap>
      <Container>
        {LINKS.siteMap.map((link, idx) =>
          <Link
            type={link.type}
            link={link.route}
            key={`${link} no. ${idx}`}>
              <LinkText>{link.title} </LinkText>
            </Link>
        )}
      </Container>
    </Wrap>
  )
}


export default Resources
