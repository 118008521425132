import React from "react";
import styled from "styled-components";
import { BodyText, SubText } from "../../../shared/Headers";
import { SectionDefault } from "../../../shared/Sections";
import background from '../../../assets/background.png'

const Header = styled(SubText)``;

const Body = styled(BodyText)``;

const Section = styled(SectionDefault)`
  // background-image: url(${background});
  // background-size: center;
  // background-size: 20%;
  // background-repeat: no-repeat;
`
const Collections = () => {
  return (
    <Section hash="collections">
      <Header>APY ASTROS COLLECTION 1</Header>
      <Body>
        Unique collectibles representing the team behind the project, Astros 
        are reserved for long standing members of APY.Finance. <br/>
        Each Astro has a unique collection of traits. Head over to the APY ASTRO<a href="https://discord.com/invite/QF8MUpHaUc" target="_blank" >Discord</a> for more info and to show off your NFT!</Body>
      <img src={background} style={{
        paddingTop: "5%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%"
      }}/>
    </Section>
  )
}

export default Collections;