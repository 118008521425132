
export const MENU_LINKS = [
  {
    body: 'Collections',
    type: 'hash',
    path: '/#collections'
  },
  {
    body: 'FAQ',
    type: 'hash',
    path: '/#FAQ'
  },
]