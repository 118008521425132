import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

import grain from "../assets/grain-gradient.png";

const Wrap = styled.a`
  position: relative;
  height: fit-content;
  text-decoration: none;
`;

const Button = styled(motion.div)`
  width: fit-content;
  height: fit-content;
  position: relative;
  font-family: archiamedium;
  text-transform: lowercase;
  font-size: 1.1em;
  background-color: #f1f1f6;
  z-index: 20;
  border: none;
  height: 50px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  box-shadow: hsla(0, 0%, 9%, 0.12) 0px 2px 4px 0px,
    hsla(0, 0%, 9%, 0.32) 0px 2px 16px 0px;
  > p {
    color: hsla(0, 0%, 12%, 0.9);
    margin: 0 10px;
  }
  > div {
    margin-right: 10px;
    margin-left: 5px;
  }
  ${"" /* @media (max-width: 415px) {
    width: 250px;
    padding: 0;
  } */}
`;

const CornerNotch = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  background-size: cover;
  width: 100%;
  height: 50px;
  background-image: url(${grain});

  ${"" /* @media (max-width: 415px) {
    width: 250px;
  } */}
`;

export const StackedButton = ({ className, children , link}) => {
  return (
    <Wrap
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      className={className}
    >
      <Button whileHover={{ x: 3, y: 3 }} whileTap={{ x: 7, y: 7 }}>
        {children}
      </Button>
      <CornerNotch />
    </Wrap>
  );
};
