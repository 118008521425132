import { AnimateSharedLayout } from "framer-motion";
import React from "react";
import { SubText } from "../../shared/Headers";
import { SectionDefault } from "../../shared/Sections";
import AnimatedLink from "./AnimatedLink";
import { MENU_LINKS } from "./constants";
import { LinkTypes as Link } from "../../shared/Links";
import styled from "styled-components";
import { Logo } from "../../shared/Logo";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  // position: absolute;
  // left: 0;
  // @media (min-width: 768px) {
  //   left: 3em;
  // }
  // @media (min-width: 1024px) {
  //   left: 4em;
  // }

  display: flex;
  align-items: center;
`;

const LogoText = styled(SubText)`
  font: 1em archiaregular;
  @media (min-width: 768px) {
    font: calc(.75em + 1.5vmin) archiaregular;
  }
`;

const Header = () => {
  return (
    <SectionDefault>
      <Container>
        {/* <SubText>APY Astro NFT Collection</SubText> */}
        <LogoLink key={"APY Logo"} type={"route"} link={"/"}>
          <Logo />
          <LogoText style={{ marginLeft: 14, marginBottom: 0 }}>
            APY Astro NFT Collection
          </LogoText>
        </LogoLink>
        <NavItems>
          <AnimateSharedLayout>
            {MENU_LINKS.map((link, idx) => {
              return (
                <Link key={link.body} type={link.type} link={link.path}>
                  <AnimatedLink
                    path={link.path}
                    key={link.body}
                    // hover={() => setSelected(link.path)}
                    // hoverEnd={() => setSelected(selectedActive)}
                    body={link.body}
                  />
                </Link>
              );
            })}
          </AnimateSharedLayout>
        </NavItems>
      </Container>
    </SectionDefault>
  );
};

export default Header;
