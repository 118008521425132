import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Main/Home";
import "./App.css";
import { EthSWRConfig } from "ether-swr";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import abi from './abi.json';
import Header from "./Components/Header/Header";
const address = "0x46D06A7111aaA2e30D3b12a5704d397EAC628748";
const ABIs = [[address,abi]];
const fallBackProvider = ethers.getDefaultProvider(1);

const App = () => {
  const { library } = useWeb3React();

  return (
    <>
        <EthSWRConfig value={{web3Provider: library ?? fallBackProvider, ABIs: new Map(ABIs)}}>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home address={address} />}/>
          </Routes>
        </EthSWRConfig>
    </>
  );
};

export default App;
