import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { flex, flexItem } from "../../../constants/variants";
import { SectionDefault as Section } from "../../../shared/Sections";
import { GridThree as Container } from "../../../shared/Lists";
import Cards from "./Cards";
import jason from "./jt.png";
import andrew from "./andrew.png";
import jonathan from "./jonathan.png";


const CARD_INFO = [
  {
    source: "",
    title: "ABOUT APY ASTRO NFTS",
    body: "APY ASTROS  are original designs claimable by early APY.Finance supporters. View eligibility by connecting your wallet. ",
    link: "https://cointelegraph.com/news/yield-farming-platform-apy-finance-locks-67-million-in-first-hour",
    logo: andrew
  },
  {
    source: "",
    title: "LIMITED TO 500 NFTs",
    body: "APY ASTROS [set 1] is a limited collection of only 500 available NFTs. Every NFT is unique, with its own specific design and rarity.",
    link: "https://cointelegraph.com/news/yield-farming-platform-apy-finance-locks-67-million-in-first-hour",
    logo: jason
  },
  {
    source: "",
    title:"MINTING DETAILS",
    body: "APY ASTROS are only eligible for minting by whitelisted early supporters.  ",
    link: "https://cointelegraph.com/news/yield-farming-platform-apy-finance-locks-67-million-in-first-hour",
    logo: jonathan
  },
]

const Info = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if(inView){
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
      <Section hash="info" size="sm">
        <Container
          ref={ref} 
          variants={flex}
          initial="hidden"
          animate={controls}
        >
          {CARD_INFO.map((article, idx) => (
            <Cards
              variants={flexItem}
              controls={controls}
              key={`info no. ${idx}`}
              link={article.link}
              logo={article.logo}
              body={article.body}
              snippet={article.snippet}
              source={article.source}
              title={article.title}
            />
          ))}
        </Container>
      </Section>
  );
};

export default Info;
