import React from "react";
import styled from "styled-components";
import { COLORS, DEVICE_SIZE } from "../../constants/UI";
import { H2Styled } from "../../shared/Headers";
import {  SectionDefault as Section } from "../../shared/Sections";

const Header = styled(H2Styled)`
  margin-bottom: 0;
  text-align: left;
  @media (min-width: ${DEVICE_SIZE.sm}) {
    text-align: center;
  }
`;
const Highlight = styled.span`
  color: ${COLORS.primary};
`

const Hero = (remainingSupply) => {
  return (
    <Section>
      <div style={{paddingTop: 70}}/>
      {
        remainingSupply.remainingSupply ? 
          <Header>There are <Highlight>{remainingSupply.remainingSupply} APY Astros</Highlight> left. Mint yours before 03/30/22</Header> :
          <Header>Connect wallet on mainnet</Header>
      }
    </Section>
  );
};

export default Hero;
