import React from 'react'
import styled from 'styled-components'

import { BodyText, H3Title } from '../../../shared/Headers'
import { StackedDiv } from '../../../shared/NotchedDiv'

const Title = styled(H3Title)`
  padding: 0;
  width: 100%;
`
const Body = styled(BodyText)`
  padding: .4em 0 0;
  font-size: .85em;
  @media (min-width: 768px) {
    width: 100%;
  }
`
const Description = styled.div`
  padding: 1em 1.5em 1em;
  // height: fit-content;
  height: calc(100% - 120px - 2em);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > a {
    color: #d0d0d0;
    width: fit-content;
    font-size: calc(0.5em + 0.5vmin);
  }
  > ${Body} {
    @media (min-width: 768px) {
      font-size: calc(0.7em + 0.5vmin);
    }
  }
  @media (min-width: 500px) {
    height: calc(100% - 120px - 2em);
  }
  @media (min-width: 768px) {
    height: calc(100% - 100px - 2em);
  }
`

const Image = styled.div`
  height: 120px;
  background: url(${props => props.background});
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #f1f1f6;
  @media (min-width: 500px) {
    height: 180px;
  }
  @media (min-width: 768px) {
    height: 180px;
  }
`

const Container = styled(StackedDiv)`
`


const Cards = ({variants, controls, title, body, logo, snippet, link, source}) => {
  return (
    <Container
      role="listitem"
      variants={variants}
      initial="hidden"
      animate={controls}
      >
      <Image background={logo} alt={`${source} Logo`}/>
      <Description>
        <div style={{flexGrow: 1, paddingBottom: "1em"}}>
          <Title>{title}</Title>
          <Body>{body}</Body>
        </div>
      </Description>
    </Container>
  )
}

export default Cards
