
export const COLORS = {
  primary: "#b454f1",
  white: '#fff',
}

export const DEVICE_SIZE = {
  sm: "768px",
  md: "992px",
  lg: "1200px",
  xlg: "1400px"
}