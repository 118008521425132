import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { ethers } from "ethers";
import useEtherSWR from "ether-swr";
import axios from "axios";
import abi from "../../abi.json";
import Hero from "./Hero";
import styled from "styled-components";
import { StackedButton } from "../../shared/Buttons";
import { SubText } from "../../shared/Headers";
import { SectionDefault as Section } from "../../shared/Sections";
import Info from "./Info/Index";
import FAQ from "./FAQ/index.js";
import Collections from "./Collections/index.js";
import Footer from "../Footers/index";

const injectedConnector = new InjectedConnector({
  supportedChainIds: [1, 4],
});

const Header = styled(SubText)`
  text-align: center;
`;

const Button = styled(StackedButton)`
  display: inline-block;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Alert = styled(SubText)`
  padding: 4px 25px;
  text-align: center;
  background-color: #f44336;
  color: white;
`;

const Success = styled(SubText)`
  padding: 5px;
  top-padding: 8px;
  bottom-padding: 8px;
  text-align: center;
  background-color: #3db08d;
  color: white;
`;

const Home = ({ address }) => {
  const { activate, account, library } = useWeb3React();
  const [isEligible, setEligible] = useState(true);
  const [eligibilityChecked, setElibilityCheck] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { data: MAX_SUPPLY } = useEtherSWR(
    library ? [address, "MAX_SUPPLY"] : []
  );
  const { data: totalSupply } = useEtherSWR(
    library ? [address, "totalSupply"] : []
  );
  const remainingSupply = MAX_SUPPLY && totalSupply && MAX_SUPPLY - totalSupply;

  useEffect(() => {
    const init = async () => {
      await activate(injectedConnector).then(() => {});
    };
    init();
  }, [activate]);

  const checkEligibility = async () => {
    let proof = [];
    setLoading(true);
    setError(false);
    try {
      proof = await axios.post(
        "https://us-central1-continual-grin-289318.cloudfunctions.net/nft_merkleProof",
        { address: account }
      );
    } catch (err) {
      console.log("cloud function failed", err);
    }
    if (proof.data.length === 0) {
      console.log("not eligible");
      setEligible(false);
    } else {
      console.log("eligible");
      setEligible(true);
    }
    setLoading(false);
    setElibilityCheck(true);
  };

  const mint = async () => {
    setError(false);
    setLoading(true);
    let proof = [];
    const signer = library.getSigner();
    try {
      proof = await axios.post(
        "https://us-central1-continual-grin-289318.cloudfunctions.net/nft_merkleProof",
        { address: account }
      );
    } catch (err) {
      console.log("cloud function failed", err);
      setLoading(false);
    }
    try {
      const Contract = new ethers.Contract(address, abi, signer);
      const trx = await Contract.mint(proof.data, account);
      await trx.wait();
      setLoading(false);
      setSuccess("Congratulations, you just minted an APY Astro");
    } catch (err) {
      const errorString = err.toString();
      console.log("error", errorString);
      const errorExample = errorString?.split(',"message":"').pop();
      const errorFinal =
        errorExample?.substring(0, errorExample?.indexOf('"}}}')) ||
        "Transaction Error";
      setLoading(false);
      setError(errorFinal);
    }
  };

  return (
    <div>
      <Hero remainingSupply={remainingSupply} />
      <Section>
        {isLoading ? 
          <Header>Loading...</Header> :
          <Container>
          {(!eligibilityChecked && !success && !error) && <Button><p onClick={checkEligibility}>Check Eligibility</p></Button>}
          {(eligibilityChecked && isEligible) &&  
            <>
              {success &&
                <>
                  <Success>{success}</Success>
                  <br/>
                  <Button link="https://opensea.io/collection/apyastros"><p>View the collection on OpenSea</p></Button>
                </>
              }  
              {error &&
                <>
                  <Alert>{error}</Alert>
                  <br/>
                  <Button link="https://opensea.io/collection/apyastros"><p>View the collection on OpenSea</p></Button>
                </>
              }
              {(!success && !error) &&
                <>
                  <Header>
                    Eligible to mint a limited early supporter APY ASTRO NFT
                  </Header>
                  <br/>
                  <Button><p onClick={mint}>Mint</p></Button>
                </>
              }
            </>
          }
          {(eligibilityChecked && !isEligible) &&
            <>
              <Header>
                Not eligible to mint an early supporter APY ASTRO NFT
                <Button link="https://testnets.opensea.io/collection/apynft-2-v2"><p>View the collection on OpenSea</p></Button>
              </Header>
            </>
          }
          </Container>
        }
      </Section>
      <Info />
      <Collections />
      <FAQ />
      <Footer/>
    </div>
  );
};

export default Home;
