import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { BodyText, SubText } from "../../../shared/Headers";
import { SectionDefault as Section } from "../../../shared/Sections";
import { FAQ_INFO } from "./constant";

const Header = styled(SubText)``;

const List = styled(motion.ol)`
  margin-left: -22px;
`
const Bullets = styled(motion.ul)``
const FAQ = () => {
  return (
    <Section hash="FAQ">
      <Header>FAQ</Header>
      <List>
        {FAQ_INFO.map((faq, i) => {
          const answers = faq.answers.map((ans, j) => {
            return <li key={'faq_'+i+'_ans_'+j}><BodyText>{ans}</BodyText></li>
          })
          return <li key={'faq_'+i}>
            <BodyText style={{topPadding:"10px", fontWeight: "1000"}}>{faq.question}</BodyText>
            <Bullets>
              {answers}
            </Bullets>
          </li>
        })}
      </List>

    </Section>
  );
};

export default FAQ;
