// Community Links
import discord from '../../assets/social-links/discord.png'
import medium from '../../assets/social-links/medium.png'
import telegram from '../../assets/social-links/telegram.svg'
import twitter from '../../assets/social-links/twitter-logo.svg'
import youtube from '../../assets/social-links/youtube.svg'
import gitbook from '../../assets/social-links/gitbook-icon.svg'
import mail from '../../assets/social-links/mail-icon.svg'

export const LINKS = {
  community: [
    {
      icon: discord,
      link: 'https://discord.gg/uzqAsmZ',
      name: 'Discord'
    },
    {
      icon: medium,
      link: 'https://medium.com/apy-finance',
      name: 'Medium'
    },
    {
      icon: telegram,
      link: 'https://t.me/apyfinancechat',
      name: 'Telegram'
    },
    {
      icon: twitter,
      link: 'https://twitter.com/apyfinance',
      name: 'Twitter'
    },
    {
      icon: youtube,
      link: 'https://www.youtube.com/channel/UCPTiSrIFrIac5jSTmHzqJ8Q',
      name: 'YouTube'
    },
    {
      icon: gitbook,
      link: 'https://docs.apy.finance/',
      name: 'Gitbook'
    },
    {
      icon: mail,
      link: 'mailto:support@apy.finance',
      name: 'Email'
    },
  ],
  siteMap: [
    {
      title: 'docs',
      link: 'https://docs.apy.finance',
      type: 'anchor'
    },
    {
      title: 'help',
      route: 'mailto:info@apy.finance',
      type: 'anchor'
    },
    {
      title: 'privacy',
      route: 'https://apy.finance/privacy-policy',
      type: 'anchor'
    },
    {
      title: 'terms',
      route: 'https://apy.finance/terms-of-service',
      type: 'anchor'
    },
    {
      title: 'add farm',
      type: 'anchor',
      route: 'https://gv5qt5b8im5.typeform.com/to/Y8czQa3E'
    },
  ],
  navBar: [
    {
      body: 'strategies',
      type: 'anchor',
      route: 'https://apy.finance/strategies'
    },
    // {
    //   body: 'docs',
    //   type: 'anchor',
    //   path: 'https://docs.apy.finance'
    // },
    {
      body: 'governance',
      type: 'anchor',
      route: 'https://apy.finance/governance'
    },
    {
      body: 'team',
      type: 'anchor',
      route: 'https://apy.finance/team'
    },
    {
      body: 'press',
      type: 'anchor',
      route: 'https://apy.finance/press'
    },
 
  ]
}
