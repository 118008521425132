export const FAQ_INFO = [
  {
    question: "Who can mint an NFT from the APY ASTRO collection?",
    answers: [
      "A random selection of users who have participated in Uniswap / Balancer LP.",
      "A small selection of active and positively contributing community members (hand-picked by the APY.Finance team).",
      "10 APY.Finance team members and CMs. (New PFPs, woo!)",
      "A selection of the first 150 users who deposited into the APY.Finance Alpha build.",
      "A selection of the first 100 users who have Boost-Locked at least 500 $APY for 4 years.",
    ],
  },
  {
    question:
      "What happens if I’m eligible for an APY, but don’t mint my NFT within 30 days?",
    answers: [
      "Any APY ASTRO NFTs that are not claimed by 3/30/2022 will be burned, further increasing exclusivity.",
    ],
  },
  {
    question: "What can I do with my APY ASTROS?",
    answers: [
      `You can view or trade your APY ASTRO on OpenSea (https://opensea.io/collection/apyastros). There may be more incentives and exclusive rewards provided to APY ASTRO holders in the future.`,
    ],
  },
  {
    question:
      "I am not eligible for an APY ASTRO, but I believe I should be. Who should I contact?",
    answers: [
      "If you believe you are eligible for an APY Astro, reach out to our support team at support@apy.finance including your wallet address and the APY ASTRO eligibility criteria you believe you fit within. Note, some of the criteria for eligibility is randomly generated.",
    ],
  },
  {
    question: "I’m not eligible for an APY ASTRO, how can I purchase one?",
    answers: [
      `You can view the collection and purchase an APY ASTRO on OpenSea (https://opensea.io/collection/apyastros)`,
    ],
  },
  {
    question: "How is my minted APY ASTRO determined?",
    answers: [
      "APY ASTROS are randomly selected from a pool of 500 total NFTs. By minting, you are claiming a random variation of the APY ASTRO.",
    ],
  },
];
